import { theme } from 'antd'
import { getAppToken, useAppURL, useConfig, useInstalledApp } from 'api'
import { Loading, PageContentWrapper } from 'components/styled'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Config, Feature } from 'types'
import { getShopId, nanoId, showMessage, useWindowDimensions } from 'utils'
import { AdminHostAppLoaderMain, AppTarget } from '@sesamiapp/app-message'

export const AppLoader = () => {
    
    //hooks:
    const { data: config } = useConfig()
    const navigate = useNavigate()
    const params = useParams()

    //if id does not provided:
    useEffect(() => { !params.id && navigate('/app') }, [params.id])

    //if apps are not enabled:
    useEffect(() => { config && !config.features.includes(Feature.APPS) && navigate('/') }, [config])

    return (config && params.id) ? <AppLoaderContent appId={params.id} config={config}/> : <></>
    
}

type Props = {
    appId: string
    config: Config
}

export const AppLoaderContent = ({ appId, config }: Props) => {

    //hooks:
    const { token: { colorBgContainer, colorBgLayout, marginXXS, marginXXL } } = theme.useToken()
    const iframe = useRef<HTMLIFrameElement>(null)
    const { SM, LG } = useWindowDimensions()
    const navigate = useNavigate()

    //state:
    const [ timestamp ] = useState((new Date()).getTime())
    const [ messageId ] = useState(nanoId([appId, timestamp, config.locale], 8))
    const [ client, setClient ] = useState<AdminHostAppLoaderMain | null>(null)
    const [ isIframeLoading, setIsIframeLoading ] = useState(true)

    //api:
    const { data: app } = useInstalledApp(appId, () => navigate('/app'))
    const { data: url } = useAppURL(
        appId,
        null,
        AppTarget.ADMIN_APP_LOADER_MAIN,
        timestamp,
        messageId,
        config.locale,
        null
    )

    //startup:
    useEffect(() => {
        if(app && iframe.current && !client){
            const cl = new AdminHostAppLoaderMain({
                timestamp,
                messageId,
                shopId: getShopId() ?? '',
                locale: config.locale,
                getToken,
                onNotification: showMessage
            })
            setClient(cl)
        }
    }, [app, iframe])

    const getToken = async () => {
        const response: any = await getAppToken(appId, null)
        try{
            const token = response.data.json.data.CreateAccessToken.token
            return token
        }catch(e){e}
        return null
    }

    return (
        <PageContentWrapper flex fullWidth noBottomPadding noFooter>

            <div style={{
                width: '100%',
                height: '100%',
                marginRight: LG ? -marginXXS : 0, // removing the effect of the layout's padding for app's scrollbar
                position: 'relative',
                display: 'flex'
            }}>

                <iframe
                    ref={iframe}
                    src={(client && url) ? url : undefined}
                    style={{ border: 'none', flex: 1 }}
                    onLoad={() => setIsIframeLoading(false)}
                />

                {isIframeLoading && (
                    <div style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: SM ? colorBgLayout : colorBgContainer,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}>
                        <Loading style={{ marginTop: LG ? -marginXXL : 0 }}/>
                    </div>
                )}

            </div>

        </PageContentWrapper>
    )
    
}
